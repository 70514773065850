import React, { useState, useEffect } from "react";
import "./InternetStatus.css";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const InternetStatus = () => {
  const [online, isOnline] = useState(navigator.onLine);
  const notify = () =>
    toast.error(
      "Sin conexión a Internet. Comprueba tu conexión Wi-Fi o de datos móviles.",
      {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      }
    );

  const setOnline = () => {
    isOnline(true);
    window.scrollTo(0, 0);
  };

  const setOffline = () => {
    isOnline(false);
    window.scrollTo(0, 0);
    notify();
  };

  useEffect(() => {
    window.addEventListener("online", setOnline);

    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);

      window.removeEventListener("offline", setOffline);
    };
  });

  return <div>{!online && <ToastContainer style={{ width: "100%" }} />}</div>;
};

export default InternetStatus;
