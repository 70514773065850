import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import Input from "../../components/ui/Input/Input";
import Button from "../../components/ui/Button/Button";
import "./Main.css";
import ListGroup from "../../components/ui/ListGroup/ListGroup";
import Navbar from "../../components/ui/Navbar/Navbar";
import Error from "../../components/ui/Error/Error";
import Modal1 from "../../components/ui/Modal/Modal";
import { Base64 } from "js-base64";
import { createArray } from "../../components/extras/createArray";
import Select1 from "../../components/ui/Select/Select";
import Label from "../../components/ui/Label/Label";

const Main = () => {
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [Icon, setIcon] = useState("");
  const [req_status, setreq_status] = useState(null);
  const [response_pon, setresponse_pon] = useState(null);
  const [updResult, setupdResult] = useState(null);
  const [modal, setModal] = useState(false);
  const [upd_Req, setUpd_req] = useState(false);
  const [sentInfo, setsentInfo] = useState(null);
  const [isLoggedIn] = useState(JSON.parse(localStorage.getItem("isLogedIn")));
  const [modalTitle, setmodalTitle] = useState("");
  const [titleClass, settitleClass] = useState("");
  const [req_action, setreq_action] = useState(null);

  // -- first input (enter `sn`)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // -- form update mdt - cdt

  const {
    handleSubmit: handleSubmit2,
    control,
    reset: reset2,
    formState: { errors: errors2 },
    setError,
  } = useForm();

  // -- open/close modal
  const Open = () => {
    setModal(!modal);
  };

  // -- array of numbers (1 - 10 cdt)

  const cabled = createArray(1, 6);

  // -- function that returns info from a specific `sn`

  const onSubmit = (data) => {
    setIsLoading(true);

    fetch(`${process.env.REACT_APP_URL}mdt-view-ruta/${data.pon}`)
      .then((response) => response.json())
      .then((json) => {
        // store the status from the request
        setreq_status(json.status);

        setIsLoading(false);

        const cd = { ...json.body };

        // check response empty fields -> action 1 or 2
        if (
          cd.cable_distribucion === "" &&
          cd.codigo_mdt === "" &&
          cd.nombre_mdt === "" &&
          cd.nombre_olt === ""
        ) {
          setreq_action(1);
        } else {
          setreq_action(2);
        }

        // store the info
        setresponse_pon(json.body);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  // -- function that updates mdt / cdt

  const onSubmitUpd = (info) => {
    // get user email - saved on localstorage

    var user_email = Base64.decode(localStorage.getItem("username"));

    // check if each object is null -- display error msg if that's true
    if (info.mdt === null && info.cdt === null) {
      setError("cdt", {
        type: "custom",
        message: "El campo 'Cable de Distribución' es requerido *.",
      });
      setError("mdt", {
        type: "custom",
        message: "El campo 'MDT' es requerido *.",
      });
    } else if (
      info.mdt &&
      info.mdt.label === response_pon.nombre_mdt &&
      info.cdt &&
      info.cdt.value === response_pon.cable_distribucion
    ) {
      setError("cdt", {
        type: "custom",
        message:
          "El valor del campo 'Cable de Distribución' debe ser distinto al actual.",
      });
      setError("mdt", {
        type: "custom",
        message: "El valor del campo 'MDT' debe ser distinto al actual.",
      });
    } else if (info.mdt && info.mdt.label === response_pon.nombre_mdt) {
      setError("mdt", {
        type: "custom",
        message: "El valor del campo 'MDT' debe ser distinto al actual.",
      });
    } else if (
      info.cdt &&
      info.cdt.value === response_pon.cable_distribucion &&
      info.mdt === null
    ) {
      setError("cdt", {
        type: "custom",
        message:
          "El valor del campo 'Cable de Distribución' debe ser distinto al actual.",
      });
    } else if ((info.cdt === null || info.mdt === null) && req_action === 1) {
      if (info.cdt === null) {
        setError("cdt", {
          type: "custom",
          message: "El campo 'Cable de Distribución' es requerido *.",
        });
      }

      if (info.mdt === null) {
        setError("mdt", {
          type: "custom",
          message: "El campo 'MDT' es requerido *.",
        });
      }
    } else {
      setUpd_req(true);

      var send = {
        Puerto: response_pon.puerto,
        Action: req_action,
        Tarjeta: response_pon.tarjeta,
        CodigoMdt: info.mdt === null ? response_pon.codigo_mdt : info.mdt.value,
        NombreMdt: info.mdt === null ? response_pon.nombre_mdt : info.mdt.label,
        Usuario: user_email,
        CodigoOlt: response_pon.codigo_olt,
        Cable:
          info.cdt === null ? response_pon.cable_distribucion : info.cdt.value,
      };

      setsentInfo(send);

      // -- send data

      fetch(`${process.env.REACT_APP_URL}mdt-upd-ruta`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          upd_mdt: send,
        }),
      })
        .then((res) => {
          setUpd_req(false);

          // store the status from the request
          setupdResult(res.status);

          // open modal and display msg
          Open();

          // if the req was successful, clear all fields
          if (res.status === 204) {
            reset({
              pon: "",
            });
            reset2();
            setresponse_pon(null);
            setreq_status(null);
            setmodalTitle("¡Datos Enviados!");
            setIcon("fa-solid fa-circle-check modal-success");
            settitleClass("modal-success-txt");
          }
        })
        .catch((error) => {
          setUpd_req(false);
          setmodalTitle("Hubo un percance con los datos enviados.");
          setIcon("fa-solid fa-circle-xmark modal-error");
          settitleClass("modal-error-txt");
          console.error("Error:", error);
        });
    }
  };

  // -- get mdt list

  useEffect(() => {
    const getData = () => {
      fetch(`${process.env.REACT_APP_URL}mdt`)
        .then((response) => response.json())
        .then((json) => {
          setComments(json.records);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getData();
  }, []);

  // -- mdt list - displayed on select
  const mdt = useMemo(
    () => [
      ...new Set(
        comments.map((item) => {
          return { label: item.Nombre_mdt, value: item.Codigo_mdt };
        })
      ),
    ],

    [comments]
  );

  const handleKeydown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="w-100 vh-100 d-flex flex-column">
      {isLoggedIn === true ? (
        <>
          <Navbar />
          <div className="mdt-container">
            <div className="text-start p-4 d-flex flex-column">
              <Label
                style_label={"label-tag label-bold"}
                text={"Ingresar PON"}
              />
              <div className="d-flex align-items-start">
                <Input
                  fieldName="pon"
                  register={register}
                  errors={errors}
                  placeHolder="HWTC1A2B345C"
                  isRequired={true}
                  maximLength={16}
                  minimLength={12}
                  keyP={handleKeydown}
                  i_disabled={isLoading}
                />

                <Button
                  leftIcon={"fa-solid fa-magnifying-glass"}
                  myClass={"btn btn-dark rounded-pill w-100 ms-2"}
                  funct={handleSubmit(onSubmit)}
                  loading={isLoading}
                />
              </div>

              {req_status && (
                <>
                  {req_status === 200 ? (
                    <>
                      <div className="mt-4 mb-3">
                        <ListGroup data={[response_pon]} />

                        <div className="pt-4">
                          <div
                            className="accordion"
                            id="accordionPanelsStayOpenExample"
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="panelsStayOpen-headingOne"
                              >
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#panelsStayOpen-collapseOne"
                                  aria-expanded="true"
                                  aria-controls="panelsStayOpen-collapseOne"
                                >
                                  Editar Datos
                                </button>
                              </h2>
                              <div
                                id="panelsStayOpen-collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="panelsStayOpen-headingOne"
                              >
                                <div className="accordion-body">
                                  <div className="mb-3">
                                    <Label
                                      style_label={"label-tag"}
                                      text={"MDT"}
                                    />

                                    <Select1
                                      name_s={"mdt"}
                                      data={mdt}
                                      control={control}
                                      errors={errors2}
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <Label
                                      style_label={"label-tag"}
                                      text={"Cable de Distribución"}
                                    />

                                    <Select1
                                      name_s={"cdt"}
                                      data={cabled}
                                      control={control}
                                      errors={errors2}
                                      custom={"Cable de Distribución"}
                                    />
                                  </div>

                                  <Button
                                    text={"Guardar"}
                                    myClass={
                                      "btn btn-dark btn-save rounded-pill w-100"
                                    }
                                    funct={handleSubmit2(onSubmitUpd)}
                                    loading={upd_Req}
                                    loadingMsg={"Guardando datos..."}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pt-5">
                        <Error
                          text={`${response_pon}`}
                          icon={"fa-solid fa-circle-xmark icon-error"}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <Modal1
            open={modal}
            close={() => Open()}
            statusReq={updResult}
            data={sentInfo}
            icon={Icon}
            title={modalTitle}
            title_class={titleClass}
          />
        </>
      ) : (
        <Error
          title={"Acceso denegado"}
          text={"La página a la que intenta acceder tiene acceso restringido."}
          page
          page_icon
          icon={"fa-solid fa-lock lock-icon"}
        />
      )}
    </div>
  );
};

export default Main;
