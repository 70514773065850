import React from "react";
import logo from "../../../assets/svg/Logo.svg";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  function logout() {
    navigate("/");
    localStorage.clear();
  }

  return (
    <div>
      <nav className="navbar navbar-dark">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarToggler"
                aria-controls="navbarToggler"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="ms-2 navbar-brand">
                <img
                  src={logo}
                  className="img-fluid logo-navbar"
                  alt="logo airtek"
                />
              </div>
            </div>

            <div>
              <div className="circle circle-lg position-relative me-2">
                <div className=" mx-auto my-auto">
                  <i
                    className={`nb-icon fas fa-sign-out-alt`}
                    onClick={logout}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="collapse navbar-collapse" id="navbarToggler">
            <div className="d-flex flex-column">
              <Link id="mdt" className="menu-item" to="/main">
                <i className="fa-solid fa-house menu-icon me-3"></i>
                Inicio
              </Link>

              <Link id="a-mdt" className="menu-item" to="/asignar-mdt">
                <i className="fa-solid fa-sitemap menu-icon me-3"></i>
                Asignar MDT
              </Link>

              <div
                className="accordion accordion-flush dropdown-m"
                id="accordionFlush"
              >
                <div className="accordion-item a-item-n">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button ai-menu collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseNavbar"
                      aria-expanded="false"
                      aria-controls="flush-collapseNavbar"
                    >
                      <i className="fa-solid fa-gear me-3"></i>
                      Configuración
                    </button>
                  </h2>
                  <div
                    id="flush-collapseNavbar"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlush"
                  >
                    <div className="accordion-body acc-menu">
                      <Link
                        id="cambiar-pwd"
                        className="sb-item menu-item"
                        to="/cambiar-pwd"
                      >
                        Cambiar Contraseña
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="btn btn-logout mb-0 text-start"
                onClick={logout}
              >
                <i className="fas fa-sign-out-alt menu-icon me-3"></i>
                Cerrar Sesión
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
