import React, { useState, useEffect } from "react";
import "./Modal.css";

import ReactModal from "react-modal";

const Modal1 = ({
  open,
  close,
  statusReq,
  data,
  title,
  txt,
  icon,
  title_class,
}) => {
  const [openM, setOpen] = useState();

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <ReactModal
      appElement={document.querySelector(".App")}
      isOpen={openM}
      className="modal modal-dialog-centered w-100"
      tabIndex="-1"
      shouldCloseOnOverlayClick={true}
      onRequestClose={close}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, .5)",
        },
      }}
    >
      <div className="modal-dialog">
        <div className="modal-content modal1">
          <div className="modal-header mhdr">
            <button
              type="button"
              className="btn-close close-modal mt-1 me-1"
              onClick={close}
            ></button>
          </div>

          <div className="modal-body mbdy h-100">
            <div className="d-flex h-100">
              <div className="m-auto text-center">
                <i className={icon}></i>
                <p className={title_class}>{title}</p>

                {txt && (
                  <>
                    <p>{txt}</p>
                  </>
                )}

                {statusReq === 204 && (
                  <>
                    <button
                      className="btn exp-btn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      Ver detalles
                    </button>

                    <div
                      className="collapse collapse-upd mt-3"
                      id="collapseExample"
                    >
                      <div className="d-flex flex-column">
                        {data &&
                          statusReq === 204 &&
                          delete data.Usuario &&
                          Object.entries(data).map(
                            ([key, val], i) =>
                              key !== "CodigoMdt" &&
                              key !== "CodigoOlt" && 
                              key !== "Action" && (
                                <div
                                  className="d-flex text-start w-100 align-items-center justify-content-center"
                                  key={i}
                                >
                                  <label className="text-start text-label">
                                    {(
                                      key.charAt(0).toUpperCase() + key.slice(1)
                                    ).replace(/_/g, " ")}
                                    :
                                  </label>

                                  <p className="ms-1 mb-0 upd-det">{val}</p>
                                </div>
                              )
                          )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal1;
