import React from "react";

const Button = ({ text, myClass, funct, leftIcon, loading, loadingMsg }) => {
  return (
    <div>
      <button className={myClass} onClick={funct} disabled={loading}>
        {loading === true ? (
          <>
            {loadingMsg}
            <div
              className={`spinner-border spinner-border-sm ${
                loadingMsg ? "ms-3" : ""
              }`}
              role="status"
            >
              <span className="sr-only">Cargando...</span>
            </div>
          </>
        ) : (
          <>
            {text}
            {leftIcon && <i className={`${leftIcon}`}></i>}
          </>
        )}
      </button>
    </div>
  );
};

export default Button;
