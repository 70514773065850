import React from "react";
import { BrowserRouter as HashRouter, Routes, Route } from "react-router-dom";
import InternetStatus from "../components/extras/InternetStatus/InternetStatus";
import Error from "../components/ui/Error/Error";
import Login from "../pages/Login/Login";
import Main from "../pages/Main/Main";
import ChangePwd from "../pages/ChangePwd/ChangePwd";
import AssignMDT from "../pages/AssignMDT/AssignMDT";

function App() {
  return (
    <div className="App">
      <InternetStatus />
      <HashRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/main" element={<Main />} />
          <Route path="/cambiar-pwd" element={<ChangePwd />} />
          <Route path="/asignar-mdt" element={<AssignMDT />} />
          <Route
            path="*"
            element={
              <Error
                title={"Oops Error"}
                text={"Esta página no está disponible."}
                page
              />
            }
          ></Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
