export const createArray = (start, end) => {
  var arr = [];
  for (var i = start; i <= end; i++) {
    arr.push({
      label: `${i}`,
      value: i,
    });
  }
  return arr;
};
