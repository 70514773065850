import React from "react";
import "./ListGroup.css";

const ListGroup = ({ data }) => {
  return (
    <div>
      <ul className="list-group">
        {data.map((obj) =>
          Object.entries(obj).map(
            ([key, value]) =>
              key !== "codigo_mdt" &&
              key !== "codigo_olt" && (
                <li key={key} className="list-group-item">
                  <span className="fw-bold me-1">
                    {(key.charAt(0).toUpperCase() + key.slice(1))
                      .replace(/_/g, " ")
                      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase()
                      )}
                    :
                  </span>
                  {value}
                </li>
              )
          )
        )}
      </ul>
    </div>
  );
};

export default ListGroup;
