import React from "react";
import { Controller } from "react-hook-form";
import Select, { createFilter } from "react-select";
import customStyles from "../../extras/customStylesSelect";
import { ErrorMessage } from "@hookform/error-message";

const Select1 = ({ name_s, data, control, errors, name_f, custom, req }) => {
  return (
    <div>
      <Controller
        name={name_s}
        control={control}
        defaultValue={null}
        rules={{
          required: {
            value: req ? true : false,
            message: `El campo ${
              custom
                ? `${custom}`
                : `${name_s.charAt(0).toUpperCase() + name_s.slice(1)}`
            } es requerido *.`,
          },
        }}
        render={({ field }) => (
          <React.Fragment>
            <Select
              {...field}
              options={data}
              noOptionsMessage={() => "No se han encontrado resultados"}
              isClearable
              styles={customStyles}
              isSearchable
              placeholder={`Seleccione ${name_f ? "una" : "un"} ${
                custom
                  ? `${custom}`
                  : `${name_s.charAt(0).toUpperCase() + name_s.slice(1)}`
              }`}
              filterOption={createFilter({ ignoreAccents: false })}
            />
            <ErrorMessage
              errors={errors}
              name={name_s}
              className={"main-error"}
              as="p"
            />
          </React.Fragment>
        )}
      />
    </div>
  );
};

export default Select1;
