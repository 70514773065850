import React from "react";
import "./Error.css";
import error404 from "../../../assets/svg/error404.svg";
import { Link } from "react-router-dom";

const Error = ({ title, text, icon, page, page_icon }) => {
  return (
    <div className="container">
      <div className={`row ${page ? "vh-100" : "h-100"}`}>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex h-100 text-center">
            <div className="mx-auto my-auto">
              <div>
                <i className={icon}></i>
                {page && page_icon === false && (
                  <img src={error404} className="img-fluid" alt="Error" />
                )}
                <h1 className={`${page ? "page-title" : "error-title"}`}>
                  {title}
                </h1>
                <p className={`${page ? "page-txt" : "error-txt"}`}>{text}</p>
                {page && (
                  <Link to="/" className="btn btn-dark rounded-pill ps-3 pe-3">
                    Ir al inicio
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
