const customStyles = {
  menu: (provided) => ({
    ...provided,
    width: "auto",
    minWidth: "100%",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: 145,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: "#ededed ",
  }),
  clearIndicator: (base) => ({
    ...base,
    color: "#bababa ",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#3b3b3b",

    paddingLeft: 5,
    paddingRight: 10,
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "13px",
    color: "#111",
    opacity: 1,
    fontWeight: 400,
  }),
  control: (base, state) => ({
    ...base,
    padding: 0,
    fontSize: "13px",
    opacity: 1,
    width: "auto",
    color: "#111",
    borderRadius: "50rem",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #111" : "1px solid #111",
    "&:hover": {
      border: "1px solid #111",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#FFF" : "#111",
    backgroundColor: state.isFocused ? "#0066FF" : null,
    padding: 8,
    fontWeight: state.isFocused ? "600" : "",
    fontSize: 13,
  }),
  singleValue: (base) => ({
    ...base,
    padding: 5,
    width: "auto",
    borderRadius: 5,
    background: " #f9f8f7",
    color: "#111",
    fontWeight: "600",
    display: "flex",
  }),
  noOptionsMessage: (base) => ({
    ...base,
    fontSize: "13px",
    color: "#0066FF",
    fontWeight: "600",
  }),
};

export default customStyles;
