import React, { useState, useEffect, useMemo } from "react";
import Error from "../../components/ui/Error/Error";
import Navbar from "../../components/ui/Navbar/Navbar";
import { Base64 } from "js-base64";
import { useForm } from "react-hook-form";
import Button from "../../components/ui/Button/Button";
import { createArray } from "../../components/extras/createArray";
import Select1 from "../../components/ui/Select/Select";
import Modal1 from "../../components/ui/Modal/Modal";
import Label from "../../components/ui/Label/Label";
import "./AssignMDT.css";

const AssignMDT = () => {
  const [isLoggedIn] = useState(JSON.parse(localStorage.getItem("isLogedIn")));
  const [comments, setComments] = useState([]);
  const [oltList, setoltList] = useState([]);
  const [updResult, setupdResult] = useState(null);
  const [upd_Req, setUpd_req] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalTitle, setmodalTitle] = useState("");
  const [titleClass, settitleClass] = useState("");
  const [Icon, setIcon] = useState("");
  const [sentInfo, setsentInfo] = useState(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  // -- open/close modal
  const Open = () => {
    setModal(!modal);
  };

  const onSubmit = (data) => {
    // get user email - saved on localstorage
    var user_email = Base64.decode(localStorage.getItem("username"));

    var send = {
      Puerto: data.puerto.label,
      Action: 1,
      Tarjeta: data.tarjeta.label,
      CodigoMdt: data.mdt.value,
      NombreMdt: data.mdt.label,
      Usuario: user_email,
      CodigoOlt: data.olt.value,
      Cable: data.cdt.value,
    };

    setsentInfo(send);

    // -- send data

    fetch(`${process.env.REACT_APP_URL}mdt-upd-ruta`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        upd_mdt: send,
      }),
    })
      .then((res) => {
        setUpd_req(false);

        // store the status from the request
        setupdResult(res.status);

        // open modal and display msg
        Open();

        // if the req was successful, clear all fields
        if (res.status === 204) {
          reset();
          setmodalTitle("¡Datos Enviados!");
          setIcon("fa-solid fa-circle-check modal-success");
          settitleClass("modal-success-txt");
        }

        // route already exists
        if (res.status === 400) {
          setmodalTitle("Ya se encuentra asignado un MDT a la ruta ingresada.");
          setIcon("fa-solid fa-circle-xmark modal-error");
          settitleClass("modal-error-txt");
        }
      })
      .catch((error) => {
        setUpd_req(false);
        setmodalTitle("Hubo un percance con los datos enviados.");
        setIcon("fa-solid fa-circle-xmark modal-error");
        settitleClass("modal-error-txt");
        console.error("Error:", error);
      });
  };

  // create arrays (numbers)
  const tarjeta = createArray(1, 7);
  const puerto = createArray(0, 15);
  const cabled = createArray(1, 6);

  // -- get mdt list

  useEffect(() => {
    const getDataMDT = () => {
      fetch(`${process.env.REACT_APP_URL}mdt`)
        .then((response) => response.json())
        .then((json) => {
          setComments(json.records);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    // -- get OLT list

    const getDataOLT = () => {
      fetch(`${process.env.REACT_APP_URL}olt`)
        .then((response) => response.json())
        .then((json) => {
          setoltList(json.records);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getDataMDT();
    getDataOLT();
  }, []);

  // -- mdt list - displayed on select
  const mdt = useMemo(
    () => [
      ...new Set(
        comments.map((item) => {
          return { label: item.Nombre_mdt, value: item.Codigo_mdt };
        })
      ),
    ],

    [comments]
  );

  // -- olt list - displayed on select
  const olt = useMemo(
    () => [
      ...new Set(
        oltList.map((item) => {
          return { label: item.Nombrenodo, value: item.Codigonodo };
        })
      ),
    ],

    [oltList]
  );

  return (
    <div className="w-100 vh-100 d-flex flex-column">
      {isLoggedIn === true ? (
        <>
          <Navbar />
          <div className="mdt-container">
            <h6 className="asignar-t">Asignar MDT</h6>

            <div className="text-start p-4 pt-0 d-flex flex-column">
              <div className="mb-2">
                <Label style_label={"label-tag label-bold"} text={"MDT"} />

                <Select1
                  name_s={"mdt"}
                  data={mdt}
                  control={control}
                  errors={errors}
                  req
                />
              </div>

              <div className="mb-2">
                <Label style_label={"label-tag label-bold"} text={"OLT"} />
                <Select1
                  name_s={"olt"}
                  data={olt}
                  control={control}
                  errors={errors}
                  req
                />
              </div>

              <div className="mb-2">
                <Label style_label={"label-tag label-bold"} text={"Tarjeta"} />
                <Select1
                  name_s={"tarjeta"}
                  data={tarjeta}
                  name_f
                  control={control}
                  errors={errors}
                  req
                />
              </div>

              <div className="mb-2">
                <Label style_label={"label-tag label-bold"} text={"Puerto"} />
                <Select1
                  name_s={"puerto"}
                  data={puerto}
                  control={control}
                  errors={errors}
                  req
                />
              </div>

              <div className="mb-2">
                <Label
                  style_label={"label-tag label-bold"}
                  text={"Cable de Distribución"}
                />
                <Select1
                  name_s={"cdt"}
                  data={cabled}
                  control={control}
                  errors={errors}
                  custom={"Cable de Distribución"}
                  req
                />
              </div>

              <div className="mt-2">
                <Button
                  text={"Guardar"}
                  myClass={"btn btn-dark btn-save rounded-pill w-100"}
                  funct={handleSubmit(onSubmit)}
                  loading={upd_Req}
                  loadingMsg={"Guardando datos..."}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Error
          title={"Acceso denegado"}
          text={
            "   La página a la que intenta acceder tiene acceso restringido."
          }
          page
          page_icon
          icon={"fa-solid fa-lock lock-icon"}
        />
      )}

      <Modal1
        open={modal}
        close={() => Open()}
        statusReq={updResult}
        data={sentInfo}
        icon={Icon}
        title={modalTitle}
        title_class={titleClass}
      />
    </div>
  );
};

export default AssignMDT;
